html.login,
body.login {
  height: 100%;
  text-align: center;
}

body.login {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  justify-content: center;
}

.login .form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.login .form-signin .checkbox {
  font-weight: 400;
}
.login .form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.login .form-signin .form-control:focus {
  z-index: 2;
}
.login .form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.login .form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.login .btn.btn-primary.btn-login {
  background-color: #ec0678;
  color: #ffffff;
  border-color: #cf0067;
}
