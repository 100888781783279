body {
  color: #444444;
}

.card-title {
  font-weight: normal;
  font-size: 130%;
  color: #222222;
}

hr {
  margin: 40px 0;
}

h3 {
  margin-top: 40px;
}
